import CountriesEnums from "@/enums/CountriesEnums";

export default [
  {
    path: CountriesEnums.MODULE_BASE_URL,
    name: 'countries',
    component: () => import('@/views/countries/countries-list/CountriesList'),
  },
  {
    path: CountriesEnums.MODULE_BASE_URL+'/:id/edit',
    name: 'edit-country',
    component: () => import('@/views/countries/countries-list/EditCountry'),
  },
]

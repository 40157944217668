import SpecialtiesEnums from "@/enums/SpecialtiesEnums";

export default [
    {
        path: SpecialtiesEnums.MODULE_BASE_URL,
        name: 'specialities',
        component: () => import('@/views/specialties/specialities-list/SpecialitiesList'),
    },
    {
        path: SpecialtiesEnums.MODULE_BASE_URL+'/:id/edit',
        name: 'edit-speciality',
        component: () => import('@/views/specialties/specialities-list/EditSpeciality'),
    },
]

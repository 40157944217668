import Vue from 'vue'

// axios
import axios from 'axios'
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 50000,
  headers: {'Admin-Id': localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).id : ''}
})

axiosIns.interceptors.response.use(
    response => response,
    error => {
      // console.log(error.response.status)
      if (error.response.status === 403) {
        // Handle 403 error globally, e.g., show a notification, redirect to a login page, etc.
        // You can also access the Vue router instance to navigate to a specific route
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')
        router.push({name : 'auth-login'});
      }
      return Promise.reject(error);
    }
)

Vue.prototype.$http = axiosIns

export default axiosIns

<template>
  <div>
    <b-form-checkbox
        v-model="checked"
        :checked=checked
        class="custom-control-success"
        name="check-button"
        switch
    />
  </div>
</template>

<script>
import {
  BFormCheckbox
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: "free-content",
  components: {BFormCheckbox},
  props: [
    'row',
    'module_be_base_url'
  ],
  data() {
    return {
      checked: this.row.is_free_content ? true : false,
    }
  },
  watch: {
    checked(newChecked, oldChecked) {
      //update status
      if (newChecked == false)
        newChecked = 0;
      else
        newChecked = 1;
      this.$http.put(this.module_be_base_url + '/' + this.row.id,
          {'is_free_content': newChecked}
      )
          .then(res => {
            if (res.data.status_code  === 1) {
              this.$emit('status_changed')
              this.showToast('success', 'top-center', res.data.message);
              return 0;
            }
          }).catch(e => {
        this.showToast('danger', 'top-center', e.response.data.message);
      })
    }
  },
  methods: {
    showToast(variant, position, message) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: message,
              variant,
            },
          },
          {
            position,
          })
    }
  }
}
</script>


export default [
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('@/views/about-us/about-list/EditAbout'),
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import('@/views/terms-and-conditions/terms-and-conditions-list/EditTermsAndConditions'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/privacy-policy/privacy-policy/EditPrivacyPolicy'),
  },
  {
    path: '/host-your-course',
    name: 'host-your-course',
    component: () => import('@/views/host-course-requests/requests-list/RequestsList'),
  },
]

<template>
  <div>
    <span>
            <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                  v-for="(action, index) in actions_list"
                  :key="index"
                  @click="handelAction(action)"
              >
                <feather-icon
                    :icon=action.icon
                    class="mr-50"
                />
                <span>{{ action.label }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
  </div>
</template>

<script>
import store from "@/store";
import {
  BDropdown, BDropdownItem
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import router from "@/router";

export default {
  name: "Actions",
  components: {BDropdown, BDropdownItem},
  props: [
    'actions_list',
    'module_be_base_url',
    'row'
  ],
  methods: {
    delete(action) {
      this.$http.delete(this.module_be_base_url + '/' + this.row.id)
          .then(res => {
            if (res.data.status_code  === 1) {
              this.showToast('success', 'top-center', res.data.message);
              this.$emit('deleted', this.row)
              return 0;
            }
          }).catch(e => {
        this.showToast('danger', 'top-center', e.response.data.message);
      })
    },
    edit(action) {
      router.push({name: action.route_name, params: {id: this.row.id}})
    },
    show(action) {
      router.push(({name: action.route_name, params: {id: this.row.id}}))
    },
    handelAction(action) {
      this[action.method](action)
    },
    showToast(variant, position, message) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: message,
              variant,
            },
          },
          {
            position,
          })
    }
  }
}
</script>

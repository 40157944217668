import OffersEnums from "@/enums/OffersEnums";

export default [
  {
    path: OffersEnums.MODULE_BASE_URL,
    name: 'offers',
    component: () => import('@/views/offers/offers-list/OffersList'),
  },
  {
    path: OffersEnums.MODULE_BASE_URL+'/:id/edit',
    name: 'edit-offer',
    component: () => import('@/views/offers/offers-list/EditOffer'),
  },
]

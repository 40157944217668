import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Status from "@core/components/Status.vue";
import FreeContent from "@core/components/FreeContent.vue";
import Actions from "@core/components/Actions";
import CustomTable from "@core/components/CustomTable";
import File from "@core/components/File";
import FileMultiple from "@core/components/FileMultiple";
import CourseImages from "@core/components/CourseImages";

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(Status.name, Status)
Vue.component(FreeContent.name, FreeContent)
Vue.component(Actions.name, Actions)
Vue.component(CustomTable.name, CustomTable)
Vue.component(File.name, File)
Vue.component(FileMultiple.name, FileMultiple)
Vue.component(CourseImages.name, CourseImages)

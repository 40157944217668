import CoursesEnum from "@/enums/CoursesEnums";
import EventsEnums from "@/enums/EventsEnums";

export default [
    {
        path: '/questions',
        name: 'dashboard-questions-list',
        component: () => import('@/views/questions/questions-list/QuestionsList.vue'),
    },
    {
        path: '/questions/:id/edit',
        name: 'dashboard-edit-question',
        component: () => import('@/views/questions/questions-list/EditQuestion.vue'),
    },
    {
        path: '/questions/create',
        name: 'dashboard-questions-create',
        component: () => import('@/views/questions/questions-list/CreateQuestion.vue'),
    },
]

import CoursesEnum from "@/enums/CoursesEnums";
import EventsEnums from "@/enums/EventsEnums";

export default [
    {
        path: '/lectures',
        name: 'dashboard-lectures-list',
        component: () => import('@/views/lectures/lectures-list/LecturesList.vue'),
    },
    {
        path: '/lectures/:id/edit',
        name: 'dashboard-edit-lecture',
        component: () => import('@/views/lectures/lectures-list/EditLecture.vue'),
    },
]

<template>
  <div>
    <b-card-code
        :title="title"
    >
      <b-button
          variant="gradient-primary"
          class="btn-icon"
          :href="module_base_url+'/create'"
      >
        <feather-icon icon="PlusIcon"/>
      </b-button>

      <!-- input search -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
                 enabled: true,
                 externalQuery: searchTerm
          }"
          :pagination-options="{
                enabled: true,
                perPage:pageLength
          }"
      >
        <template
            slot="table-row"
            slot-scope="props"
        >

          <!-- Column: flag -->
          <div
              v-if="props.column.field === 'flag'"
              class="text-nowrap"
          >
            <b-avatar
                :src="props.row.flag"
                class="mx-1"
            />
          </div>

          <!-- Column: rate -->
          <div
              v-if="props.column.field === 'rate'"
              class="text-nowrap"
          >
            <b-form-rating
                id="rating-inline"
                inline
                readonly
                no-border
                variant="warning"
                :value="props.row.rate"
            />
            <span>{{props.row.rate}}</span>
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'image'">
          <div
              class="text-nowrap"
          >
            <b-avatar
                :src="props.row.image"
                class="mx-1"
            />
          </div>
        </span>
          <span v-else-if="props.column.field === 'status'">
          <status
              :row=props.row
              :module_be_base_url=module_be_base_url
          >
          </status>
        </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
          <actions
              :actions_list=actionsList
              :module_be_base_url=module_be_base_url
              :row=props.row
              @deleted="emitDeletedAction"
          ></actions>
        </span>

          <!-- Column: Common -->
          <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-code>

  </div>
</template>

<script>
import {
  BAvatar, BButton, BFormGroup, BFormInput, BFormSelect, BPagination
} from 'bootstrap-vue'
import BCardCode from "@core/components/b-card-code/BCardCode";
import { BFormRating, BCardText } from 'bootstrap-vue'
import {VueGoodTable} from "vue-good-table";
import store from "@/store";

export default {
  name: "CustomTable",
  components: {
    BCardCode,
    BFormRating,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton
  },
  props: [
    'row',
    'module_be_base_url',
    'module_base_url',
    'title',
    'columns',
    'actionsList',
    'rows'
  ],
  data() {
    return {
      pageLength: 10,
      dir: false,
      searchTerm: '',

    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    emitDeletedAction() {
      this.$emit('deleted')
    }
  }
}
</script>
<style lang="scss">
.justify-content-end {
  float: right;
}

@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

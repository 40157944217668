import CoursesEnum from "@/enums/CoursesEnums";

export default [
    {
        path: '/categories',
        name: 'dashboard-categories-list',
        component: () => import('@/views/courses/courses-list/SideMenuCoursesCategoriesList'),
    },
    {
        path: '/categories/:id/questions',
        name: 'dashboard-add-categories-question',
        component: () => import('@/views/questions/questions-list/CategoryCreateQuestion'),
    }
]
